<template>
  <a-modal
    title="订单发货"
    :width="500"
    :visible="visible"
    :confirmLoading="confirmLoading"
    :destroyOnClose="true"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <a-form :form="form">
      <!-- 评价内容 -->
      <a-form-item label="快递公司" has-feedback :labelCol="{ span: 4 }" :wrapperCol="{ span: 18 }">
        <a-select
          v-decorator="['dictId', { rules: [{ required: false, message: '请选择快递公司' }] }]"
          allow-clear
          placeholder="请选择"
        >
          <a-select-option v-for="(item, index) in expressCompanyList" :key="index" :value="item.status">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="快递单号" has-feedback :labelCol="{ span: 4 }" :wrapperCol="{ span: 18 }">
        <a-input
          placeholder="请输入快递单号"
          allow-clear
          v-decorator="['sendComCourierId', { rules: [{ required: false, message: '请输入快递单号' }] }]"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { orderSend } from '@/api/modular/mallLiving/orderAdmin'

export default {
  data() {
    return {
      visible: false, //modal框显示状态
      confirmLoading: false,
      expressCompanyList: [], //快递公司列表
      form: this.$form.createForm(this),
      id: '', //父组件传过来的订单id
    }
  },
  methods: {
    //初始化方法
    send(record) {
      console.log(record)
      this.visible = true
      this.id = record.id

      setTimeout(() => {
        //将订单类型回显
        this.form.setFieldsValue({
          orderType: Number(record.orderType) || 1,
        })
      }, 200)
      // 调取接口，获取字典id
      this.axios.post('/api/dict/fineList', null, { params: { code: 'LOGISTICS_COMPANY_CODE' } }).then((res) => {
        // 遍历出快递公司以及对应的id组成新数组
        this.expressCompanyList = res.data.map((item) => {
          return { status: item.id, name: item.name }
        })
      })
    },
    // 确定按钮操作
    handleSubmit() {
      this.confirmLoading = true
      this.form.validateFields((errors, values) => {
        if (!errors) {
          orderSend({ id: this.id, ...values }).then((res) => {
            if (res.success) {
              this.$message.success('发货成功！')
              this.$emit('ok', values)
              this.confirmLoading = false
              this.handleCancel()
            } else {
              setTimeout(() => {
                this.confirmLoading = false
              }, 600)
            }
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    // 点击遮罩层或右上角叉或取消按钮的操作
    handleCancel() {
      this.visible = false //关闭对话框
      this.confirmLoading = false //关闭确认按钮加载状态
      this.form.resetFields() //重置表单
    },
  },
}
</script>
